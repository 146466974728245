import PropTypes from 'prop-types';
import React from 'react';
import Title from 'stoomlink-commons-ui/Title';
import Container from './styledComponent';

const Quote = ({ children, layout }) => (
  <Container layout={layout}>
    <Title
      dangerouslySetInnerHTML={{
        __html: children,
      }}
      level={2}
    />
  </Container>
);

Quote.propTypes = {
  children: PropTypes.node,
  layout: PropTypes.string, // center or null
};

export default Quote;
