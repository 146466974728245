import PropTypes from 'prop-types';
import React from 'react';

import Title from 'stoomlink-commons-ui/Title';
import BodyText from 'stoomlink-commons-ui/BodyText';

import Button from '../Button';
import {
  Container,
  PictureContainer,
  TextContainer,
} from './styledComponent';

const ActionCard = ({
  title, children, buttons, className,
}) => (
  <div id="intercom">
    <Container className={className}>
      <PictureContainer>
        <TextContainer>
          <Title
            dangerouslySetInnerHTML={{
              __html: title,
            }}
            level={2}
          />
          <BodyText
            dangerouslySetInnerHTML={{
              __html: children,
            }}
            type="subhead"
          />
          {buttons
            && buttons.map((button, i) => {
              const { title: buttonTitle, href } = button;
              return (
                <Button href={href} className="intercom-bot-launcher" key={`${i + buttonTitle}`}>{buttonTitle}</Button>
              );
            })}
        </TextContainer>
      </PictureContainer>
    </Container>
  </div>
);

ActionCard.propTypes = {
  title: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  })).isRequired,
  children: PropTypes.node,
};

export default ActionCard;
