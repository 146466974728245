import PropTypes from 'prop-types';
import React from 'react';

import BodyText from 'stoomlink-commons-ui/BodyText';
import Title from 'stoomlink-commons-ui/Title';
import Button from '../Button';
import {
  ListContainer,
  ListItem,
} from './styledComponent';

const List = ({ items, theme }) => (
  <ListContainer>
    {items.map((item, i) => {
      const {
        title, content, buttons,
      } = item;
      return (
        <ListItem theme={theme} key={`${title + i}`}>
          <div>
            {title && (
              <Title
                dangerouslySetInnerHTML={{ __html: title }}
                level={5}
              />
            )}
            <BodyText dangerouslySetInnerHTML={{
              __html: content,
            }}
            />
            {(buttons && buttons.length >= 1) && (
              <>
                {buttons.map((button) => {
                  const { title: buttonTitle, href } = button;
                  return (
                    <Button href={href} key={`${buttonTitle + i}`}>{buttonTitle}</Button>
                  );
                })}
              </>
            )}
          </div>
        </ListItem>
      );
    })}
  </ListContainer>
);

List.propTypes = {
  items: PropTypes.array,
  theme: PropTypes.string,
};

export default List;
