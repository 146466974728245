import React from 'react';

import LayersManager from 'stoomlink-commons-ui/LayersManager';
import ThemeProvider from 'stoomlink-commons-ui/ThemeProvider/ThemeProvider';
import BodyText from 'stoomlink-commons-ui/BodyText/BodyText';
import Title from 'stoomlink-commons-ui/Title';

import Button from '../components/Button';
import TextAndPicture from '../components/TextAndPicture';
import Container from '../layouts/Container';

import Layout from '../layouts/Layout';
import Head from '../layouts/Head';

import List from '../components/List';

import Footer from '../components/Footer';

import FloatingParagraph from '../components/FloatingParagraph';
import Card from '../components/Card';
import ActionCard from '../components/ActionCard';

import Hero from '../components/Hero';
import Quote from '../components/Quote';

import FeatureBlock from '../components/FeatureBlock';

// =============================================================================
// Content
// =============================================================================
import enterprise from '../../content/personas/enterprise';
import cities from '../../content/personas/cities';

const pagesBySlugs = {
  enterprise,
  cities,
};

// =============================================================================
// Page
// =============================================================================

const PersonaPage = ({ pageContext: { slug } }) => {
  const data = pagesBySlugs[slug];
  const {
    pageTitle,
    title, summary,
    mission,
    keyFeatures,
    features,
    cases,
    endingQuote,
    callToAction,
  } = data;

  return (
    <ThemeProvider themeStyle="light">
      <LayersManager>
        <Layout>
          <Head title={pageTitle} />
          <Hero title={title} />

          <TextAndPicture src={mission.src} parallax={mission.parallax}>
            <FloatingParagraph buttons={mission.buttons}>
              {summary}
            </FloatingParagraph>
          </TextAndPicture>

          {keyFeatures && (
            <Container>
              <List items={keyFeatures} />
            </Container>
          )}
          {features && (
            <>
              {features.map((product, i) => {
                const {
                  title: featuresTitle,
                  content, src,
                } = product;
                return (
                  <FeatureBlock
                    key={`${featuresTitle + i}`}
                    title={featuresTitle}
                    src={src}
                  >
                    <BodyText type="eyebrow">
                      {content}
                    </BodyText>
                  </FeatureBlock>
                );
              })}
            </>
          )}

          {cases && (
            <>
              {cases.map((case_, i) => {
                const {
                  title, content,
                  src, buttons,
                } = case_;
                return (
                  <FeatureBlock
                    key={`${title + i}`}
                    title={title}
                    src={src}
                    buttons={buttons}
                  >
                    <BodyText
                      dangerouslySetInnerHTML={{
                        __html: content,
                      }}
                      type="eyebrow"
                    />
                  </FeatureBlock>
                );
              })}
            </>
          )}

          {endingQuote && (
            <Container>
              <Quote>
                {endingQuote}
              </Quote>
            </Container>
          )}

          {callToAction && (
            <ActionCard
              title={callToAction.title}
              buttons={callToAction.buttons}
            >
              {callToAction.content}
            </ActionCard>
          )}
          <Footer />
        </Layout>
      </LayersManager>
    </ThemeProvider>
  );
};

export default PersonaPage;
