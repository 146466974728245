import styled from 'styled-components';
import { colors } from '../../theme/index';

export const ListContainer = styled.ul`
  padding: 0;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px){
    flex-direction: column;
  }
`;

export const ListItem = styled.li`
  display: flex;
  flex-wrap: wrap;
  width: 30%;
  @media screen and (max-width: 1340px){
    width: 31%;
  }
  align-content: space-between;

  border:  ${(props) => ((props.theme === 'secondary') ? `4px solid ${colors.main}` : 'none')};
  padding:  32px;

  margin-right: 16px;

  :last-child {
    margin-right: 0;
  }

  h5 {
    margin-bottom: 16px;
  }

  button {
    margin-top: 24px;
  }

  @media (max-width: 768px){
    width: 100%;
    margin: 0 0 24px 0;

    :last-child {
    margin: 0;
    }
  }
`;
