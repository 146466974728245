import Mockup from '../../src/assets/images/products/mockups/smartdisplays.png';
import Photo1 from '../../src/assets/images/products/shots/smartdisplays-micromob-1.jpg';
import Photo2 from '../../src/assets/images/products/shots/smartdisplays-station-1.jpg';
import Photo3 from '../../src/assets/images/products/shots/smartdisplays-kiosk.jpg';

export const data = {
  pageTitle: 'Cities',
  title: 'We have solutions <br/>to build the <span class="focus-accent">cities</span><br/>of <span class="focus-main">tomorrow</span>.',
  summary: 'At the Belgian Mobility Company, we believe <span class="focus-accent">greener mobility is key</span> to a more sustainable future. That\'s why we developed a tool suite that unlocks its full potential.<br/><br/>Stay relevant to your citizens. Support them as they take the plunge into the mobility of the future.<br/><br/>Let us help you <span class="focus-accent">foster the modal shift.</span>',
  mission: {
    src: Mockup,
    parallax: [-148, 0],
    buttons: [
      { title: 'Let\'s meet!', href: '#intercom' },
    ],
  },

  keyFeatures: [
    {
      title: '<span class="focus-main">Pro-Grade</span> Tools',
      content: 'Our products are built on top of a performant cloud-based self-orchestrated micro-services architecture. Rendered by our elegant custom UI.<br/>This means they are bullet-proof and ready to meet <span class="focus-accent">your highest expectations.</span>',

    },
    {
      title: '<span class="focus-main">New & Old</span> Mobility combined',
      content: 'We want you to stay relevant to those you care for. That\'s why we integrated <span class="focus-accent">all mobility players</span>. From Public Transport Operators (PTOs) to micro-mobility. And even your good ol\' private car.',
    },
    {
      title: '<span class="focus-main">Data-driven</span> Approach',
      content: 'We record millions of real-time data to feed them into our products. Then we combine them in a smart way. That\'s how we make sure we offer you <span class="focus-accent">relevant insights.</span> And how we make sure you stay relevant to those you care for.',
    },
  ],

  cases: [
    {
      title: 'Designed for <br/>the <span class="focus-main">new mobility</span>.',
      content: 'Thanks to our microservices architecture, we add new mobility providers every day. Unlike old industrial solutions, our SmartDisplays were designed for the latest forms of mobility: shared bikes, shared cars, shared everything. Without forgetting our good ol\' transit networks!',
      src: Photo1,
    },
    {
      title: '<span class="focus-main">Easily</span> setup and deployed.',
      content: 'Whether you already have a digital signage solution<br/>and just want to plug ours on, or whether you wish we do it all — installation & hardware: We do it all! One screen — like we did for BECi — or an industrial country-wide deploy like we did in Wallonia for TEC: SmartDisplays are designed to support any scale of deployment.',
      src: Photo2,
    },
    {
      title: 'Dynamic & <span class="focus-main">Interactive</span>.',
      content: 'SmartDisplays also come in a “Kiosk-like flavour”: on top of the non-interactive digital signage solution, SmartDisplays can also be fully interactive. It includes killer features like Real-Time route planning, live network disruptions alerts, live maps,... ',
      src: Photo3,
    },
  ],

  callToAction:
  {
    title: 'Looks promising?',
    content: 'We are happy to further discuss how we can help you build the cities of tomorrow.',
    buttons: [{ title: 'Schedule a Call', href: '#intercom' }],
  },
};

export default data;
