import styled from 'styled-components';
import { stoomlinkColors as colors } from 'stoomlink-commons-ui/colors';
import CarsPhoto from '../../assets/images/cars.jpg';
import ParisPhoto from '../../assets/images/news/stoomlink-cta-news-optimized.jpg';


export const Container = styled.section`
  background: ${colors.Gray200.hex};
  padding: 84px 0;
  @media screen and (max-width: 667px){
    padding: 64px 0;
  }
  &.newsCard {
    padding: 120px 60px;
    @media (max-width: 768px){
      padding: 84px 0;
    }
    @media screen and (max-width: 667px){
      padding: 64px 0;
    }
  }
`;

// TODO: Use local image URL somehow
export const PictureContainer = styled.div`
  background-image: url(${CarsPhoto});
  background-size: cover;
  background-position: center;
  position: relative;
  width: 1240px;
  margin: 0 auto;
  padding: 65px 104px;
  @media screen and (max-width: 1340px){
    width: 980px;
  }
  @media screen and (max-width: 980px){
    width: 80%;
  }
  @media screen and (max-width: 800px){
    width: 90%;
    padding: 56px 68px;
  }
  @media screen and (max-width: 667px){
    width: 85%;
    padding: 0;
  }
  .newsCard & {
    background-image: url(${ParisPhoto});
  }
`;

export const TextContainer = styled.div`
  padding: 30px 60px;
  background: #ffffff;
  width: 50%;
  @media screen and (max-width: 1340px){
    width: 60%;
  }
  @media screen and (max-width: 980px){
    width: 100%;
  }
  @media screen and (max-width: 667px){
    padding: 32px 20px;
  }
  .newsCard & {
    padding: 80px 60px;
    @media (max-width: 768px){
      padding: 48px 60px;
    }
    @media (max-width: 667px){
      padding: 32px 20px;
    }
  }
  h2 {
    margin-bottom: 16px;
  }
  p:last-of-type {
    margin-bottom: 32px;
  }
`;
