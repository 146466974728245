import PropTypes from 'prop-types';
import React from 'react';

import Title from 'stoomlink-commons-ui/Title/Title';

import Button from '../Button';
import {
  Container,
  TextContainer,
  PictureContainer,
  ButtonsContainer,
  ButtonContainer,
} from './styledComponent';


const FeatureBlock = ({
  title, children, src, alt, buttons,
}) => (
  <Container>
    <PictureContainer>
      <img src={src} alt={alt} />
    </PictureContainer>
    <TextContainer>
      <Title
        dangerouslySetInnerHTML={{
          __html: title,
        }}
        level={2}
      />
      {children}

      {buttons && (
      <ButtonsContainer>
        {buttons.map((button, i) => {
          const { title: buttonTitle, href } = button;
          return (
            <ButtonContainer key={`${buttonTitle + i}`}>
              <Button href={href}>{buttonTitle}</Button>
            </ButtonContainer>

          );
        })}
      </ButtonsContainer>
      )}

    </TextContainer>

  </Container>
);

FeatureBlock.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
};

export default FeatureBlock;
