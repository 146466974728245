import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  margin: 32px 0;
  @media (max-width: 768px){
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  flex-basis: 50%;
  margin-left: 32px;
  padding-right: 128px;
  position: sticky;
  h2 {
    margin-bottom: 24px;
  }
  @media (max-width: 768px){
    width: 85%;
    padding: 0;
    margin: 0 auto;
    margin-top: 24px;
  }
`;

export const PictureContainer = styled.div`
  flex-basis: 50%;
  height: 80vh;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: 768px){
    height: 60vh;
  }
`;

export const ButtonsContainer = styled.div`
  margin-top: 64px;
  @media (max-width: 768px){
    margin-top: 24px;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 16px;
`;
